import * as React from 'react'
import Grid from '@material-ui/core/Grid'
type HeaderType = {
  headerText?: string
}

const Header: React.SFC<HeaderType> = ({ headerText }) => (
  <Grid item>
    <Grid direction="column" container>
      <h1>{headerText}</h1>
    </Grid>
  </Grid>
)

Header.defaultProps = {
  headerText: 'Upload CSV file',
}

export default Header
