import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { DndProvider, useDrop } from 'react-dnd'
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend'
import CSVTextArea from './CSVTextArea'
import { voidCallback } from '../../types/index'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    maxWidth: '100%',
    minHeight: '100%',
    // overflowX: 'auto',
    // overflowY: 'auto',
  },
  content: {
    minHeight: 350,
    // minWidth: 650, // this need to change on smaller breakpoint
  },
}))

const requiredColumns = ['Col1', 'Col2']

const rows: Record<string, string>[] = [0, 1, 2, 3].map(row => {
  return requiredColumns.reduce(
    (acc, col) => ({
      ...acc,
      [col]: `${col}-${row}`,
    }),
    {}
  )
})

type StaticCSVT = {
  onClick: voidCallback
}

const StaticCSV: React.SFC<StaticCSVT> = ({ onClick }) => {
  const classes = useStyles()
  const [, drop] = useDrop({
    accept: NativeTypes.FILE,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  return (
    <Paper ref={drop} className={classes.root} onClick={onClick}>
      <Table className={classes.content}>
        <TableHead>
          <TableRow>
            {requiredColumns.map(col => (
              <TableCell align="center" key={col}>
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rIndex: number) => (
            <TableRow key={JSON.stringify(row)}>
              {requiredColumns.map((col, cIndex) => {
                return (
                  <TableCell align="center" key={`${rIndex}-${cIndex}`}>
                    {row[col]}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

const ExampleCSV: React.SFC<{}> = () => {
  const [isShowingCSV, setShowCSV] = React.useState(false)
  const showCSV = (): void => setShowCSV(true)
  const hideCSV = (): void => setShowCSV(false)
  return (
    <Grid container direction="column">
      {isShowingCSV ? (
        <CSVTextArea onClick={hideCSV} useStyles={useStyles} />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <StaticCSV onClick={showCSV} />
        </DndProvider>
      )}
    </Grid>
  )
}

export default ExampleCSV
