/* eslint-disable */
import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import GlobalContext from '../GlobalContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    },
    button: {
      margin: `${theme.spacing(1)}px`,
      textTransform: 'none',
      padding: `0 ${theme.spacing(1)}px`,
      '&$disabled': {
        cursor: 'not-allowed',
        color: 'grey',
      },
    },
    chip: {
      margin: `${theme.spacing(1)}px`,
      borderRadius: `${theme.spacing(1) / 2}px`,
    },
    disabled: {},
  })
)

// ugly hack. Grid forward all props to the component
// but it is typed as 'div'
const AnyGrid = (Grid as unknown) as any

const UploadForm: React.SFC<{}> = () => {
  const classes = useStyles()
  const { disableSubmit, fileName } = React.useContext(GlobalContext)
  return (
    <AnyGrid
      container
      classes={{
        root: classes.root,
      }}
      direction="row"
      justify="space-evenly"
      component="form"
      encType="multipart/form-data"
      action="/tete"
      method="POST"
    >
      <Grid xs={6} item>
        <Grid container justify="flex-end">
          <Grid item>
            <label htmlFor="csv-input">
              {!fileName ? (
                <Button
                  disableFocusRipple
                  disableRipple
                  component="span"
                  variant="contained"
                  className={classes.button}
                >
                  Select CSV
                </Button>
              ) : (
                <Chip
                  clickable
                  size="small"
                  className={classes.chip}
                  color="primary"
                  onDelete={() => {}}
                  icon={<AssessmentOutlinedIcon />}
                  label={fileName}
                />
              )}
            </label>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} item>
        <Grid container justify="flex-start">
          <Grid
            item
            style={{ cursor: disableSubmit ? 'not-allowed' : 'pointer' }}
          >
            <Button
              disabled={disableSubmit}
              disableFocusRipple
              disableRipple
              type="submit"
              variant="contained"
              color="primary"
              classes={{
                root: classes.button,
                disabled: classes.disabled,
              }}
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </AnyGrid>
  )
}

export default UploadForm
