import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import GlobalContext from './GlobalContext'
import Header from './Header/Header'
import Upload from './Upload'
import useSingletonCSV from '../hooks/useSingletonCSV'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: 'center',
      backgroundColor: '#282c34',
      minHeight: '100vh',
    },
  })
)

const App: React.SFC<{}> = () => {
  const {
    handleCSVChange,
    disableSubmit,
    inputFileRef,
    csvFileName,
  } = useSingletonCSV()

  const classes = useStyles()
  return (
    <GlobalContext.Provider value={{ disableSubmit, fileName: csvFileName }}>
      <Grid className={classes.root} spacing={3} container direction="column">
        <Header />
        <input
          id="csv-input"
          type="file"
          accept="text/csv"
          name="dataframe"
          ref={inputFileRef}
          onChange={handleCSVChange}
          style={{ display: 'none' }}
        />
        <Upload />
      </Grid>
    </GlobalContext.Provider>
  )
}

export default App
