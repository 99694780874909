import * as React from 'react'

type GlobalContextT = {
  disableSubmit: boolean
  fileName: string | null
}

const GlobalContext = React.createContext<GlobalContextT>({
  disableSubmit: true,
  fileName: null,
})

export default GlobalContext
