import * as React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import UploadForm from './UploadForm'
import ExampleCSV from './ExampleCSV'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexBasis: '75%',
      margin: 'auto 0',
      flexGrow: 9,
      backgroundColor: '#293035',
    },
  })
)

const Upload: React.SFC<{}> = () => {
  const classes = useStyles()
  return (
    <Grid
      className={classes.root}
      container
      spacing={0}
      direction="row"
      alignContent="space-around"
    >
      <Grid item xs={12}>
        <UploadForm />
      </Grid>
      <Grid item xs={12}>
        <ExampleCSV />
      </Grid>
    </Grid>
  )
}

export default Upload
