import * as React from 'react'
import { inputFileChangeHandlerT, GlobalSingletonCSVHookT } from '../types'

const useSignletonCSV = (): GlobalSingletonCSVHookT => {
  const inputFileRef = React.useRef<HTMLInputElement>(null)
  const [disableSubmit, setDisableSubmit] = React.useState(true)
  const [csvFileName, setCsvFileName] = React.useState('')
  const handleCSVChange: inputFileChangeHandlerT = e => {
    const inputTag = e.target
    setDisableSubmit(!inputTag.value)
    const fileName =
      inputTag.files && inputTag.files[0] && inputTag.files[0].name
    if (fileName) {
      setCsvFileName(fileName)
    }
  }

  return {
    handleCSVChange,
    disableSubmit,
    inputFileRef,
    csvFileName,
  }
}

export default useSignletonCSV
