import * as React from 'react'

import useOnClickOutside from 'use-onclickoutside'
import { voidCallback } from '../../types'

const exampleCSVVaules = `Example Address	City	State	Zip	Name	Phone Number	Group	URL
1 Crossgates Mall Road	Albany	NY	12203	Apple Store Cross Gates	(518) 869-3192	Example Group 1	http://www.apple.com/retail/crossgates/
Duke Rd &amp; Walden Ave	Buffalo	NY	14225	Apple Store Walden Galleria	(716) 685-2762	Example Group 2	http://www.apple.com/retail/walden/
630 Old Country Rd.	Garden City	NY	11530	Apple Store Roosevelt Field	(516) 248-3347	Example Group 3	http://www.apple.com/retail/rooseveltfield/
160 Walt Whitman Rd.	Huntington Station	NY	11746	Apple Store Walt Whitman	(631) 425-1563	Example Group 3	http://www.apple.com/retail/waltwhitman/
9553 Carousel Center Drive	Syracuse	NY	13290	Apple Store Carousel	(315) 422-8484	Example Group 2	http://www.apple.com/retail/carousel/
2655 Richmond Ave	Staten Island	NY	10314	Apple Store Staten Island	(718) 477-4180	Example Group 1	http://www.apple.com/retail/statenisland/
7979 Victor Road	Victor	NY	14564	Apple Store Eastview	(585) 421-3030	Example Group 1	http://www.apple.com/retail/eastview/
1591 Palisades Center Drive	West Nyack	NY	10994	Apple Store Palisades	(845) 353-6756	Example Group 2	http://www.apple.com/retail/palisades/
125 Westchester Ave.	White Plains	NY	10601	Apple Store The Westchester	(914) 428-1877	Example Group 3	http://www.apple.com/retail/thewestchester/
103 Prince Street	New York	NY	10012	Apple Store SoHo	(212) 226-3126	Example Group 2	http://www.apple.com/retail/soho/`

type CSVTextAreaT = {
  onClick: voidCallback
  useStyles: () => Record<'root' | 'content', string>
}

const CSVTextArea: React.SFC<CSVTextAreaT> = ({ useStyles, onClick }) => {
  const classes = useStyles()
  const ref = React.useRef<HTMLDivElement>(null)
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)
  useOnClickOutside(ref, onClick)
  React.useEffect(() => {
    if (textAreaRef === null) {
      return
    }
    if (textAreaRef.current === null) {
      return
    }
    if (typeof textAreaRef.current.select !== 'function') {
      return
    }
    textAreaRef.current.select()
  }, [])
  return (
    <div ref={ref} className={classes.root}>
      <textarea
        ref={textAreaRef}
        className={classes.content}
        id="sourceData"
        wrap="off"
        readOnly
        value={exampleCSVVaules}
        title="click to copy/paste"
      />
    </div>
  )
}

export default CSVTextArea
